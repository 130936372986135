import { Link, Tags } from "gatsby"
import { H4, Paragraph } from "./typography";
import { CATEGORY_MAP } from './header';

const tagsClassNameList = [
  'blue',
  'green',
  'orange',
  'red',
  'primary'
];

const PostLink = ({ title, slug, date, excerpt, link, des, category, tags }) => (
  <article>
    <header>
      <H4 className="mt-2 mb-3"> 
        {
          (CATEGORY_MAP.newsletter === category && link) ? <a href={link}  target="_blank" >{title}</a> : <Link to={ link || slug}> {title || slug}</Link>
        }
      </H4>
      <small>{date}</small>
      <small> 
      {
        [category, ...tags].filter(Boolean).map((tag, index) => ( <div 
          key={tag}
          style={{fontSize: 12 }}
          className={`ml-4  text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-blue-500 text-inverse rounded-full tag`}
        >
          {tag}
        </div>))
      }
      </small>
    </header>
    <section>
      <Paragraph>{ excerpt || des }</Paragraph>
    </section>
  </article>
)

export default PostLink
 
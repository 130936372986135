import * as React from "react"
import Layout from "./layout"
import SEO from "./seo"
import Footer from "./home-footer"
import PostList from "./post-list"
import { Grid } from "./grid"
import Bio from './bio'
import { CATEGORY_MAP } from "./header"

const Posts = ({ location, posts, siteTitle, socialLinks }) => (
  <Layout location={location} title={siteTitle}>
    <SEO title="newsletter" />
    <Grid as="main" className="prose prose-light mb-24 dark:prose-dark">
      <Bio />
      <PostList posts={posts} />
    </Grid>
   
    <Footer socialLinks={socialLinks} />
  </Layout>
)

const PostsWrapper = ({ location, data }) => {
  const { site, allBlogPost } = data || {};
  return (
    <Posts
      location={location}
      posts={allBlogPost.nodes.filter(node => node.category === CATEGORY_MAP.newsletter)}
      siteTitle={site.siteMetadata.title}
      socialLinks={site.siteMetadata.social}
    />
  )
}

export default PostsWrapper

import { graphql } from "gatsby"
import NewsletterPages from "../components/newsletter"

export default NewsletterPages

export const query = graphql`
  query NewsletterQuery($limit: Int!, $filter: BlogPostFilterInput) {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    allBlogPost(
      sort: { fields: [date, title], order: DESC }
      filter: $filter
      limit: $limit
    ) {
      nodes {
        id
        excerpt
        slug
        title
        des
        date(formatString: "MMMM DD, YYYY")
        tags
        category
        link
      }
    }
  }
`
